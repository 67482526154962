import { useEffect, useState } from "react"
import api from "../components/api"


const Medicao = (props) => {

    const { documentoDemanda_id } = props
    const perfil = sessionStorage.getItem('perfil')

    const [processo, setProcesso] = useState('')
    const [numeroContrato, setNumeroContrato] = useState('')
    const [nomeGestorContrato, setNomeGestorContrato] = useState('')
    const [cpfGestorContrato, setCpfGestorContrato] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [centroCustoDescri, setCentroCustoDescri] = useState('')
    const [valorNF, setValorNF] = useState('')
    const [numeroNF, setNumeroNF] = useState('')
    const [pedido, setPedido] = useState('')
    
    const [chaveAssinaturaGestorContrato, setChaveAssinaturaGestorContrato] = useState('')
    const [nomeAssinaturaGestorContrato, setNomeAssinaturaGestorContrato] = useState('')
    const [cpfAssinaturaGestorContrato, setCpfAssinaturaGestorContrato] = useState('')
    const [pendenteAssinatura, setPendenteAssinatura] = useState(true)
    const [dataAssinaturaGestorContrato, setDataAssinaturaGestorContrato] = useState('')
    const [assinaturaGestorContrato, setAssinaturaGestorContrato] = useState()

    
    const [assinaturaGestaoContratos, setAssinaturaGestaoContratos] = useState('')


    const [centroCusto, setCentroCusto] = useState('')
    const [descricaoDemanda, setDescricaoDemanda] = useState('')
    const [dataVencimentoNF, setDataVencimentoNF] = useState('')
    const [dataEmissaoNF, setDataEmissaoNF] = useState('')
    const [competencia, setCompetencia] = useState('')
    const [produto, setProduto] = useState('')


    const [prestacaoRealizada, setPrestacaoRealizada] = useState('')
    const [prestadorCumpriu, setPrestadorCumpriu] = useState('')    
    const [prestadorNaoCumpriuObservacao, setPrestadorNaoCumpriuObservacao] = useState('')
    const [nivelSatisfacao, setNivelSatisfacao] = useState('')
    const [observacoesAdicionais, setObservacoesAdicionais] = useState('')
    const [informacaoComplementar, setInformacaoComplementar] = useState('')

    const [situacaoDocumento, setSituacaoDocumento] = useState('')

    const [arquivoNome, setArquivoNome] = useState()
    

    const salvaNumeroPedido = () => {

        var dataPost = {

            pedido: pedido,

        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma salvar o número do pedido?',
		    buttons: {
		        Sim: function () {


                                    
                    api.put(`demandaMedicaoPedido/${documentoDemanda_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        if (result.data.status == 'ok') {

                            window.toastr.success('Registro salvo com sucesso!')
                            //assinaDemandaMedicao()

                        }
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const carregaMedicao = () => {

        api.get(`demandaMedicao/${documentoDemanda_id}`).then((result) => {

            //console.log(result.data)
            var data = result.data[0]

            setProcesso(data.processo)
            setArquivoNome(data.arquivoNome)
            setNumeroContrato(data.numeroContrato)
            setNomeGestorContrato(data.nomeGestorContrato)
            setCpfGestorContrato(data.cpfGestorContrato)
            setItemContabilDescri(data.itemContabilDescri)
            setCentroCustoDescri(data.centroCustoDescri)
            setValorNF(data.valorNF)
            setPedido(data.pedido)
            setChaveAssinaturaGestorContrato(data.chaveAssinaturaGestorContrato)
            setNomeAssinaturaGestorContrato(data.nomeAssinaturaGestorContrato)
            setCentroCusto(data.centroCusto)
            setDescricaoDemanda(data.descricaoDemanda)
            setDataVencimentoNF(data.dataVencimentoNF)
            setDataEmissaoNF(data.dataEmissaoNF)
            setCompetencia(data.competencia)
            setNumeroNF(data.numeroNF)
            setProduto(data.produto)
            //setPendenteAssinatura(data.pendenteAssinatura ? data.pendenteAssinatura : true)
            setPendenteAssinatura(data.chaveAssinaturaGestaoContratos ? false : true)

            setPrestacaoRealizada(data.prestacaoRealizada)
            setPrestadorCumpriu(data.prestadorCumpriu == true ? 'S' : data.prestadorCumpriu == false ? 'N' : '')
            setPrestadorNaoCumpriuObservacao(data.prestadorNaoCumpriuObservacao)
            setNivelSatisfacao(data.nivelSatisfacao)
            setObservacoesAdicionais(data.observacoesAdicionais)
            setInformacaoComplementar(data.informacaoComplementar)
            setSituacaoDocumento(data.situacaoDocumento)

            setAssinaturaGestorContrato(`Em ${data.dataAssinaturaGestorContrato} por ${data.cpfAssinaturaGestorContrato} - ${data.nomeAssinaturaGestorContrato} \nChave ${data.chaveAssinaturaGestorContrato}`)

            setAssinaturaGestaoContratos(`Em ${data.dataAssinaturaGestaoContratos} por ${data.cpfAssinaturaGestaoContratos} - ${data.nomeAssinaturaGestaoContratos} \nChave ${data.chaveAssinaturaGestaoContratos}`)

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        carregaMedicao()

    }, [documentoDemanda_id])



    const salvar = () => {

        if (prestacaoRealizada == '' || prestacaoRealizada == null) {

            window.toastr.error('<b>A prestação de serviços foi realizada de acordo com a escala/hora estipulada em contrato</b> não pode ficar em branco!')
            return false

        }

        if (prestadorCumpriu == '' || prestadorCumpriu == null) {

            window.toastr.error('<b>O Prestador cumpriu todos os termos e condições do contrato?</b> não pode ficar em branco!')
            return false

        } else {

            if (prestadorCumpriu == 'N' && (prestadorNaoCumpriuObservacao == '' || prestadorNaoCumpriuObservacao == null)) {

                window.toastr.error('<b>Como foi resolvido?</b> não pode ficar em branco!')
                return false

            }

        }

        if (nivelSatisfacao == '' || nivelSatisfacao == null) {

            window.toastr.error('<b>Qual é o seu nível geral de satisfação com os serviços prestados pela contratada</b> não pode ficar em branco!')
            return false

        }

        var dataPost = {

            prestacaoRealizada: prestacaoRealizada,
            prestadorCumpriu: prestadorCumpriu == 'S' ? true : false,
            prestadorNaoCumpriuObservacao: prestadorNaoCumpriuObservacao,
            nivelSatisfacao: nivelSatisfacao,
            observacoesAdicionais: observacoesAdicionais,
            informacaoComplementar: informacaoComplementar,
            
        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {


                                    
                    api.put(`demandaMedicao/${documentoDemanda_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        if (result.data.status == 'ok') {

                            window.toastr.success('Registro salvo com sucesso!')
                            assinaDemandaMedicao()

                        }
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const assinaGestaoContratos = () => {


        var dataPost = {

            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Assinatura Gestão de Contratos',
		    content: 'Deseja assinar o documento?',
		    buttons: {
		        Sim: function () {

        
                    api.put(`demandaMedicaoAssinaGestaoContratos/${documentoDemanda_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        if (result.data.status == 'ok') {

                            window.toastr.info('Documento assinado com sucesso!')
                            

                            var data = result.data

                            if (result.data.status == 'ok') {

                                props.carregaMedicoes()

                                /*
                                setChaveAssinaturaGestaoContratos(data.chaveAssinaturaGestaoContratos)
                                setCpfAssinaturaGestaoContratos(data.cpfAssinaturaGestaoContratos)                                
                                setDataAssinaturaGestaoContratos(data.dataAssinaturaGestaoContratos)
                                setNomeAssinaturaGestaoContratos(data.nomeAssinaturaGestaoContratos)
                                */

                                setSituacaoDocumento('F')
    
                                //if (data.pendenteAssinatura == false) {
    
                                    setAssinaturaGestaoContratos(`Em ${data.dataAssinaturaGestaoContratos} por ${data.cpfAssinaturaGestaoContratos} - ${data.nomeAssinaturaGestaoContratos} \nChave ${data.chaveAssinaturaGestaoContratos}`)
                    
                                //}

                            }

                            
                            
                        }
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }



    const assinaDemandaMedicao = () => {

        var dataPost = {

            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Assinatura Gestor do Contrato',
		    content: 'Deseja assinar o documento?',
		    buttons: {
		        Sim: function () {

        
                    api.put(`demandaMedicaoAssina/${documentoDemanda_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        if (result.data.status == 'ok') {

                            window.toastr.success('Documento assinado com sucesso!')

                            var data = result.data

                            if (result.data.status == 'ok') {

                                props.carregaMedicoes()

                                setChaveAssinaturaGestorContrato(data.chaveAssinaturaGestorContrato)
                                setCpfAssinaturaGestorContrato(data.cpfAssinaturaGestorContrato)
                                setPendenteAssinatura(data.pendenteAssinatura)
                                setDataAssinaturaGestorContrato(data.dataAssinaturaGestorContrato)
                                setNomeAssinaturaGestorContrato(data.nomeAssinaturaGestorContrato)
                                setPendenteAssinatura(false)
                                setSituacaoDocumento('G')
    
                                //if (data.pendenteAssinatura == false) {
    
                                    setAssinaturaGestorContrato(`Em ${data.dataAssinaturaGestorContrato} por ${data.cpfAssinaturaGestorContrato} - ${data.nomeAssinaturaGestorContrato} \nChave ${data.chaveAssinaturaGestorContrato}`)
                    
                                //}

                            }

                            
                            
                        }
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const pdfAtestadoNF = () => {

        //window.toastr.info('Em desenvolvimento')

        api.get(`demandaMedicaoPDF/${documentoDemanda_id}`).then((result) => {

            //console.log(result.data)
            window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const pdfNotaFiscal = () => {


        api.get(`demandaMedicaoPDF/${documentoDemanda_id}`).then((result) => {

            //console.log(result.data)
            window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${arquivoNome}`)

        }).catch((err) => {

            console.log(err.response)

        })

    }

    return (

        <div>


            <div className="row">

                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Contrato</label>
                        <input type="text" className="form-control" disabled={true} value={numeroContrato} style={{ backgroundColor: '#ebe4f5' }}/>
                    
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Processo</label>
                        <input type="text" className="form-control" disabled={true} value={processo} />
                    
                    </fieldset>
                </div>
                <div className="col-md-6">
                    <fieldset className="form-group">
                        <label className="form-label" >Gestor do Contrato</label>
                        <input type="text" className="form-control" disabled={true} value={`${cpfGestorContrato} - ${nomeGestorContrato}`} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Descrição Demanda</label>
                        <textarea type="text" className="form-control" rows="2" disabled={true} value={descricaoDemanda} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Item Contábil</label>
                        <textarea type="text" className="form-control" rows="2" disabled={true} value={itemContabilDescri} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Centro de Custo</label>
                        <textarea type="text" className="form-control" rows="2" disabled={true} value={`${centroCusto} - ${centroCustoDescri}`} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Produto</label>
                        <input type="text" className="form-control" disabled={true} value={produto} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Pedido</label>
                        <input type="text" className="form-control" maxLength={25} disabled={ situacaoDocumento == 'G' && (perfil == 'Compras' || perfil == 'Administrador') ? false : true} value={pedido} onChange={event => setPedido(event.target.value)} />
                    
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" >Competência</label>
                        <input type="text" className="form-control" disabled={true} value={competencia} />
                    
                    </fieldset>
                </div>
                
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Número NF</label>
                        <input type="text" className="form-control" disabled={true} value={numeroNF} />
                    
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Valor NF (R$)</label>
                        <input type="text" className="form-control" disabled={true} value={valorNF} style={{ backgroundColor: '#c4f5f0', textAlign: 'right'}} />
                    
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Emissão NF</label>
                        <input type="text" className="form-control" disabled={true} value={dataEmissaoNF} />
                    
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" >Vencimento NF</label>
                        <input type="text" className="form-control" disabled={true} value={dataVencimentoNF} />
                    
                    </fieldset>
                </div>

                <div className="col-md-12">
                    <section class="card card-blue mb-3">
                        <header class="card-header">
                            Avaliação da Prestação de Serviço
                            <button type="button" class="modal-close">
                                
                            </button>
                        </header>
                        <div class="card-block">
                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >A prestação de serviços foi realizada de acordo com a escala/hora estipulada em contrato: *</label>
                                    <select className="form-control" value={prestacaoRealizada} onChange={event => setPrestacaoRealizada(event.target.value)} disabled={ !pendenteAssinatura }>
                                        <option value="">[Selecione]</option>    
                                        <option value="S">Sim</option>    
                                        <option value="N">Não</option>    
                                        <option value="NA">Não se aplica</option>    
                                    </select>
                                    
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <br/><br/>
                                <fieldset className="form-group">
                                    <label className="form-label" >O Prestador cumpriu todos os termos e condições do contrato? *</label>
                                    <select className="form-control" value={prestadorCumpriu} onChange={event => setPrestadorCumpriu(event.target.value)} disabled={ !pendenteAssinatura }>
                                        <option value="">[Selecione]</option>    
                                        <option value="S">Sim</option>    
                                        <option value="N">Não</option>    
                                        
                                    </select>
                                
                                </fieldset>
                            </div>
                            <div className="col-md-12" style={{ display: prestadorCumpriu == 'N' ? 'block': 'none'}}>
                                <fieldset className="form-group">
                                    <label className="form-label" >Como foi resolvido? *</label>
                                    <textarea className="form-control" rows="3" maxLength={4000} value={prestadorNaoCumpriuObservacao} onChange={event => setPrestadorNaoCumpriuObservacao(event.target.value)} disabled={ !pendenteAssinatura }/>
                                       
                                
                                </fieldset>
                            </div>

                            <div className="col-md-12">
                                <br/><br/>
                                <fieldset className="form-group">
                                    <label className="form-label" >Qual é o seu nível geral de satisfação com os serviços prestados pela contratada? *</label>
                                    <select className="form-control" value={nivelSatisfacao} onChange={event => setNivelSatisfacao(event.target.value)} disabled={ !pendenteAssinatura }>
                                        <option value="">[Selecione]</option>    
                                        <option value="O">Ótimo</option>    
                                        <option value="B">Bom</option>    
                                        <option value="R">Regular</option>    
                                        <option value="Z">Ruim</option>    
                                        
                                    </select>
                                
                                </fieldset>
                            </div>
                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Observações adicionais, se necessário:</label>
                                    <textarea className="form-control" rows="3" maxLength={4000} value={observacoesAdicionais} onChange={event => setObservacoesAdicionais(event.target.value)} disabled={ !pendenteAssinatura }/>
                                       
                                
                                </fieldset>
                            </div>

                            <div className="col-md-12">
                                <fieldset className="form-group">
                                    <label className="form-label" >Existe mais alguma informação ou feedback que você gostaria de compartilhar sobre a experiência com o Prestador?</label>
                                    <textarea className="form-control" rows="3" maxLength={4000} value={informacaoComplementar} onChange={event => setInformacaoComplementar(event.target.value)} disabled={ !pendenteAssinatura }/>
                                       
                                
                                </fieldset>
                            </div>
                            <div className="col-md-12" style={{ display: !pendenteAssinatura ? 'block' : 'none'}}>
                                <fieldset className="form-group">
                                    <label className="form-label">Assinatura Gestor do Contrato</label>
                                    <textarea rows="2" className="form-control" value={assinaturaGestorContrato} disabled style={{ backgroundColor: '#e9f2cb'}} />
                                </fieldset>
                            </div>

                            <div className="col-md-12" style={{ display: situacaoDocumento == 'F' ? 'block' : 'none'}}>
                                <fieldset className="form-group">
                                    <label className="form-label">Assinatura Gestão de Contratos</label>
                                    <textarea rows="2" className="form-control" value={assinaturaGestaoContratos} disabled style={{ backgroundColor: '#cfcbf2'}} />
                                </fieldset>
                            </div>


                            <div className="col-md-12" style={{ textAlign: 'left'}}>

                                <button type="button" className="btn btn-inline btn-default-outline" onClick={() => props.setShow(false)}>Fechar</button>
                                <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => {props.setShow(false);props.setShowCompra(true)}}>Ver contrato</button>
                                <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()} style={{ display: situacaoDocumento == 'P' ? 'table-row' : 'none' }} >Salvar e assinar Gestor Contrato</button>
                                <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => salvaNumeroPedido()} style={{ display: situacaoDocumento == 'G' && (perfil == 'Compras' || perfil == 'Administrador') ? 'table-row' : 'none' }} >Salvar número de pedido</button>
                                <button type="button" className="btn btn-inline btn-info-outline" onClick={() => assinaGestaoContratos()} style={{ display: situacaoDocumento == 'G' && (perfil == 'Compras' || perfil == 'Administrador') ? 'table-row' : 'none' }} >Assinar Gestão de Contratos</button>
                                <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => pdfNotaFiscal()} >PDF Nota Fiscal</button>
                                <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => pdfAtestadoNF()} style={{ display: situacaoDocumento == 'F' ? 'table-row' : 'none' }} >PDF Atestado de Nota Fiscal</button>
                                

                            </div>

                        </div>
                    </section>
                </div>

            </div>


        </div>

    )


}


export default Medicao