import { useEffect, useState, PureComponent } from "react"
import io from 'socket.io-client'
import { removeDuplicates, fmtMoney, fmtMes } from "../functions/generalFunctions"
import api from '../components/api'
import moment from 'moment'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';



const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        //console.log(payload)
        //console.log(payload[0].payload.mes)
      return (
        <div className="custom-tooltip">
          <p className="label">
            <div style={{ textAlign: 'left'}}>
                <font color={payload[0].stroke}>{`${label}/${payload[0].payload.ano1}: ${payload[0].payload.totalAno1.toLocaleString('pt-BR')} demanda(s)`}</font>
                <div style={{ marginBottom: 5}}/>
                <font color={payload[1].stroke}>{`${label}/${payload[0].payload.ano2}: ${payload[0].payload.totalAno2.toLocaleString('pt-BR')} demanda(s)`}</font>
            </div>
          </p>
          
        </div>
      );
    }
  
    return null;
  };

class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
  
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          
        </text>
      );
    }
  }



const Home = () => {

    const perfil = sessionStorage.getItem('perfil')

    const ano = moment().format('YYYY')
    const anoAnterior = parseInt(ano) -1

    const [usuariosOn, setUsuariosOn] = useState()
    const [listaUsuariosOn, setListaUsuariosOn] = useState()
    const [totalResultado, setTotalResultado] = useState(0)

    const [totalReceitas, setTotalReceitas] = useState(0)
    const [totalDespesas, setTotalDespesas] = useState(0)
    const [totalSaldoAnterior, setSaldoAnterior] = useState(0)

    const [resultadoDashboard, setResultadoDashboard] = useState()
    const [resultadoTotal, setResultadoTotal] = useState()

    const [dataChart, setDataChart] = useState([])
    
    const [solicitante, setSolicitante] = useState('')
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])

    const [totalDemandas, setTotalDemandas] = useState(0)
    const [totalDemandas_anoAnterior, setTotalDemandas_anoAnterior] = useState(0)
    

    useEffect(() => {

        //console.log(perfil, 'aqui perfil inicial effect')
        if (perfil == 'ConsultaCompras') {

            setSolicitante(sessionStorage.getItem('solicitante'))
            
            
        }


    }, [perfil])


    const graficoCompras = async () => {

        var dataPost = {

            solicitante: solicitante,

        }

        //console.log(dataPost, 'aa')
        setDataChart([])
        

        await api.post('relatorioDemandaNovo', dataPost).then((result) => {

            //console.log(result.data, solicitante)
            //console.log(solicitante)
            //console.log(result.data)
            

                setDataChart(result.data.map((rs) => {


                    return {

                        mes: `${fmtMes(rs.mes).substring(0, 3)}`,
                        ano1: rs.ano1,
                        ano2: rs.ano2,
                        totalAno1: rs.totalAno1,
                        totalAno2: rs.totalAno2,

                    }

                }))


                var totalDemandasX = 0
                var totalDemandasY = 0

                for (var k = 0; k < result.data.length; k++) {

                    var totalDemandasX = totalDemandasX + parseInt(result.data[k].totalAno1)
                    var totalDemandasY = totalDemandasY + parseInt(result.data[k].totalAno2)

                }

                setTotalDemandas(totalDemandasY.toLocaleString('pt-BR'))
                setTotalDemandas_anoAnterior(totalDemandasX.toLocaleString('pt-BR'))



        }).catch((err) => {

            console.log(err.response)

        })

    }

    async function renderizaUsuarios() {

        if (listaUsuariosOn) {

            //console.log(listaUsuariosOn)

            setUsuariosOn(listaUsuariosOn.map((rs) => 

                <tr style={{ fontSize: '11px'}}>
                    <td>
                        {rs.nome}
                        &nbsp;
                        <span class={`label label-pill label-primary`}>{rs.perfil == 'ConsultaCompras' ? 'Padrão' : rs.perfil}</span>
                        
                    </td>
                </tr>
                
            ))


            //dataTableRender('tabelaUsuariosOn')
            
        }

    }


    const posicaoAtualPDF = async () => {


        api.get(`posicaoAtualPDF/${ano}`).then((result) => {

            //console.log(result.data)

            if (result.data) {

                //window.open(`http://172.16.1.253:3378/pdf/${result.data.arquivo}`)
                //window.open(`https://apiportaltransp.fajsaude.com.br/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                //const linkAPI = process.env.REACT_APP_API

            }

            



        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaDashboard = () => {


        api.get(`dashboard/${ano}`).then((result) => {

            //console.log(result.data)
            //setTotalReceitas(fmtMoney(result.data[0].totalReceitas / 1000))
            setTotalReceitas(
                new Intl.NumberFormat("pt-BR", {
                  notation: "compact",
                  compactDisplay: "short",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                }).format(result.data[0].totalReceitas)
            )

            //setTotalDespesas(fmtMoney(result.data[0].totalDespesas / 1000))            
            setTotalDespesas(
                new Intl.NumberFormat("pt-BR", {
                    notation: "compact",
                    compactDisplay: "short",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                }).format(Math.abs(result.data[0].totalDespesas))
            )
            //setTotalResultado((fmtMoney(result.data[0].saldoAtual / 1000)))
            setTotalResultado(
                new Intl.NumberFormat("pt-BR", {
                    notation: "compact",
                    compactDisplay: "short",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                }).format(result.data[0].saldoAtual)
            )

            //setSaldoAnterior((fmtMoney(result.data[0].saldoAnterior / 1000)))
            setSaldoAnterior(
                new Intl.NumberFormat("pt-BR", {
                    notation: "compact",
                    compactDisplay: "short",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                }).format(result.data[0].saldoAnterior)
            )

            var fluxoCaixa = result.data[0].fluxoCaixa

            //console.log(fluxoCaixa)

            setResultadoDashboard(fluxoCaixa.map((rs, key) => 
                        
                    <tr className={`table-${ parseInt(key) % 2 == 0 ? 'active' : parseInt(key) % 2 == 1 ? 'success' : 'info' }`} style={{ fontSize: '12px', textAlign: 'right'}}>
                        <td>
                            {fmtMes(rs.mes)}
                        </td>
                        <td>
                            {fmtMoney(rs.receitas_anoAnterior, 2)}
                        </td>
                        <td>
                            {fmtMoney(rs.receitas, 2)}
                        </td>
                        <td>
                            {fmtMoney(rs.despesas_anoAnterior, 2)}
                        </td>
                        <td>
                            {fmtMoney(rs.despesas, 2)}
                        </td>
                        <td>
                            <font color={ rs.resultado_anoAnterior < 0 ? 'red' : 'blue'}>{fmtMoney(rs.resultado_anoAnterior, 2)}</font> 
                        </td>

                        <td>
                            <font color={ rs.resultado < 0 ? 'red' : 'blue'}>{fmtMoney(rs.resultado, 2)}</font> 
                        </td>

                    </tr>


            ))


            var resultadoTotal = result.data[0].totalFluxoCaixa

            //console.log(resultadoTotal)
            setResultadoTotal(resultadoTotal.map((rs) =>
            
                <tr style={{ fontSize: '12px', textAlign: 'right'}}>
                    <th>
                        Total
                    </th>
                    <th>
                        {fmtMoney(rs.totalReceitas_anoAnterior, 2)}
                    </th>
                    <th>
                        {fmtMoney(rs.totalReceitas, 2)}
                    </th>
                    <th>
                        {fmtMoney(rs.totalDespesas_anoAnterior, 2)}
                    </th>
                    <th>
                        {fmtMoney(rs.totalDespesas, 2)}
                    </th>
                    <th>
                        <font color={ rs.totalResultado_anoAnterior < 0 ? 'red' : 'blue'}>{fmtMoney(rs.totalResultado_anoAnterior, 2)}</font>
                    </th>
                    <th>
                        <font color={ rs.totalResultado < 0 ? 'red' : 'blue'}>{fmtMoney(rs.totalResultado, 2)}</font>
                    </th>
                </tr>
            
            ))
            
            

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

            setResultadoSolicitantes(result.data.map((rs) => 

                <option value={rs.solicitante}>{rs.solicitante}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        //var socket = io('http://192.168.255.165:3378');
        var socket = io(process.env.REACT_APP_API)
        //var socket = io('https://api-faj.koderia.com.br');

            

        var nome = sessionStorage.getItem('nome')
        var usuario_id = sessionStorage.getItem('usuario_id')
        
        
        /*
        socket.emit('news', { hello: 'world' });

        socket.on('message', function (message) {
            console.log(message);
        });

        */

        var dataPost = { 
            
            nome: nome, 
            usuario_id: usuario_id,
            perfil: perfil,
        }

        //console.log(dataPost)

        socket.emit('news', dataPost);

        /*
        socket.on('message', function (message) {
            //console.log(message, '!!');
        });
        */
       


        
          
        socket.on('usuariosOn', function (usuariosOn) {
            
            //console.log(usuariosOn.filter(onlyUnique), '!?')
            setListaUsuariosOn(removeDuplicates(usuariosOn, "nome"))
            
        });

        socket.on('previousMessages', function(message) {
            //console.log(message)
            /*
            var dataPost = {
                us_codigo: sessionStorage.getItem('us_codigo'),
                nome: sessionStorage.getItem('nome'),
            }
            message.push(dataPost)
            */
           //console.log('aqui')
           
            var existe = false

            for (var i = 0; i < message.length; i++) {

                if (message[i].us_codigo == sessionStorage.getItem('us_codigo')) {
                    var existe = true
                    break;
                }

            }
            
            if (!existe) {

                var dataPost = {
                    us_codigo: sessionStorage.getItem('us_codigo'),
                    nome: sessionStorage.getItem('nome'),
                }
                message.push(dataPost)

            }

            setListaUsuariosOn(removeDuplicates(message, "nome"))

        })
          
        
        
        if (perfil != 'ConsultaCompras' && perfil != null) { 
            
            carregaSolicitantes()

        }
        
    }, [])


    
    useEffect(() => {

        if (perfil != '' && perfil != null) {

            if ((perfil == 'ConsultaCompras' && solicitante != '') || (perfil != 'ConsultaCompras')) {

                graficoCompras()
    
            }

        }
        

    }, [solicitante, perfil])
      



    useEffect(() => {

        carregaDashboard()
        renderizaUsuarios()        

    }, [listaUsuariosOn])



    return (

        <div>

            <div className="col-xl-12" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || sessionStorage.getItem('perfil') == 'Gestor' || sessionStorage.getItem('perfil') == 'Financeiro' ? 'block' : 'none' }}>
                <div className="row">
                    <div className="col-sm-3">
                        <article className="statistic-box yellow">
                            <div>
                                <div className="number" style={{ fontSize: '35px' }}>R$ {totalSaldoAnterior}</div>
                                <div className="caption"><div>Saldo ano anterior</div></div>
                                
                            </div>
                        </article>
                    </div>
                    <div className="col-sm-3">
                        <article className="statistic-box purple">
                            <div>
                                <div className="number" style={{ fontSize: '35px' }}>R$ {totalReceitas}</div>
                                <div className="caption"><div>Receitas</div></div>
                                
                            </div>
                        </article>
                    </div>
                    <div className="col-sm-3">
                        <article className="statistic-box red">
                            <div>
                                <div className="number" style={{ fontSize: '35px' }}>R$ {totalDespesas}</div>
                                <div className="caption"><div>Despesas</div></div>
                                
                            </div>
                        </article>
                    </div>
                    
                    
                    <div className="col-sm-3">
                        <article className="statistic-box green">
                            <div>
                                <div className="number" style={{ fontSize: '35px' }}>R$ {totalResultado}</div>
                                <div className="caption"><div>Saldo atual</div></div>
                                
                            </div>
                        </article>
                    </div>
                    
                    
                </div>
            </div>




            <div className="col-xl-12" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || sessionStorage.getItem('perfil') == 'Gestor' || sessionStorage.getItem('perfil') == 'Financeiro' ? 'block' : 'none' }}>


                <section class="box-typical scrollable">
                    <header class="box-typical-header">
                        <div class="tbl-row">
                            <div class="tbl-cell tbl-cell-title">
                                <h3>Posição atual</h3>
                            </div>
                        </div>
                    </header>
				
                        <table id="table-sm" class="table table-bordered table-hover table-sm" >
							<thead>
                                <tr>
                                    <th rowspan="2" style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        &nbsp;                                    

                                    </th>
                                    <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        
                                        Receita

                                    </th>
                                    <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        
                                        Despesa

                                    </th>
                                    <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        
                                        Resultado

                                    </th>
                                    
                                </tr>
                            <tr>

                            {[...Array(3)].map(() => (
                                <>
                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center' }}>
                                        {anoAnterior}
                                    </th>
                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center' }}>
                                        {ano}
                                    </th>
                                </>
                            ))}
                                

                            </tr>
                                    
                            </thead>
                            <tbody>
                                {resultadoDashboard}
                            </tbody>
                            <tfoot>
                                {resultadoTotal}
                                <tr>
                                    <td colSpan={7} style={{ textAlign: 'right' }}>

                                        <button type="button" class="btn btn-inline btn-danger" onClick={() => posicaoAtualPDF()}>PDF</button>

                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                
                </section>


                <br/>

                


            </div>


            <div className="col-xl-12" >

                <section class="box-typical scrollable">
                    <header class="box-typical-header">
                        <div class="tbl-row">
                            <div class="tbl-cell tbl-cell-title">
                                <h3>Demandas de Compras
                                    </h3>
                                
                            </div>
                        </div>
                    </header>
                    <body>
                        
                        <br/>

                        {

                            perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Gestor' ?

                            (
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Solicitante</label>
                                        <select className="form-control" value={solicitante} onChange={event => setSolicitante(event.target.value)} disabled={ perfil != 'Administrador' && perfil != 'Compras' && perfil != 'Gestor' ? true : false }>
                                            <option value="">[Todos]</option>
                                            {resultadoSolicitantes}
                                        
                                        </select>
                                        
                                    </fieldset>
                                </div>

                            )

                            :

                            null

                        }                       
                    
                    

                        <ResponsiveContainer width="100%" height={200}>

                                

                            <LineChart data={dataChart} margin={{ top: 5, right: 20, bottom: 5, left: 0 } }>
                                <Line type="monotone" dataKey="totalAno1" stroke="#8884d8" label={<CustomizedLabel />} />
                                <Line type="monotone" dataKey="totalAno2" stroke="#bf714b" label={<CustomizedLabel />} />
                                

                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="mes" style={{ fontSize: '11px', fontFamily: 'Arial' }}
                                
                                
                                />
                                <YAxis style={{ fontSize: '10px', fontFamily: 'Arial' }} 
                                tick={false}
                                //hide={true}
                                //tickFormatter={tick => {return tick.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });}}
                                />
                                
                                
                                
                                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                            </LineChart>

                            


                        </ResponsiveContainer>
                        <table id="table-sm" class="table table-bordered table-hover table-sm" >
							<thead>
                                <tr>
                                    <td>Demandas em {ano}: <span class="label label-pill label-warning">{totalDemandas}</span></td>
                                </tr>
                                <tr>
                                    <td>Demandas em {anoAnterior}: <span class="label label-pill label-info">{totalDemandas_anoAnterior}</span></td>
                                </tr>
                            </thead>
                        </table>
                    </body>
                </section>

            </div>

            <div className="col-xl-12">

                    
                
                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    <thead>
                            <tr>
                                
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    On-line                                    

                                </th>
                            </tr>
                            
                    </thead>
                    {usuariosOn}
                </table>
            
            


            </div>


            

        </div>

    )


}


export default Home