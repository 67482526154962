import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../components/api'
import moment from 'moment'
import { sleep } from '../functions/generalFunctions'

import LoaderIcon from "react-loader-icon";

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import "../components/styles.css";

registerLocale('ptBR', ptBR)



const DemonstrativoFinanceiroDetalhado = () => {


    var dataInicio = `${moment().format('YYYY')}-01-01`

    var dataInicio = moment(dataInicio, 'YYYY-MM-DD').toDate()
    
    const areasFinanceirasLogin = JSON.parse(sessionStorage.getItem('areaFinanceira'))
    const perfil = sessionStorage.getItem('perfil')

    const [carregando, setCarregando] = useState('none')

    const [startDate, setStartDate] = useState(dataInicio);
    const [endDate, setEndDate] = useState(new Date())

    const [dataUltimoMovimento, setDataUltimoMovimento] = useState()

    const [tipoDemonstrativo, setTipoDemonstrativo] = useState('T')
    const [resultado, setResultado] = useState([])


    const [itemContabil, setItemContabil] = useState()
    const [fluxoCaixa, setFluxoCaixa] = useState('')
    const [codigoNatureza, setCodigoNatureza] = useState('')
    const [area, setArea] = useState('')

    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState()

    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [colorItemContabil, setColorItemContabil] = useState('white')
    const [resultadoItemContabil, setResultadoItemContabil] = useState('')

    const [resultadoNatureza, setResultadoNatureza] = useState()
    const [naturezaDescri, setNaturezaDescri] = useState('')
    const [colorNatureza, setColorNatureza] = useState('white')

    const [resultadoArea, setResultadoArea] = useState()
    const [total, setTotal] = useState(0)
    const [totalReceitas, setTotalReceitas] = useState(0)
    const [totalDespesas, setTotalDespesas] = useState(0)
    const [totalPagar, setTotalPagar] = useState(0)
    const [totalReceber, setTotalReceber] = useState(0)
    const [totalPrev, setTotalPrev] = useState(0)
    const [totalResultado, setTotalResultado] = useState(0)

    const [resultadoPagar, setResultadoPagar] = useState([])
    const [resultadoReceber, setResultadoReceber] = useState([])

    

    const consultaDataUltimoMovimento = () => {


        api.get('listaDataMovimento').then((result) => {

            //console.log(result.data)      
            setDataUltimoMovimento(result.data[0].dataMovimento)      

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

        if (itemContabil) {

            setColorItemContabil('orange')

        } else {

            setColorItemContabil('white')

        }
        

    }, [itemContabil])



    useEffect(() => {

        if (codigoNatureza) {

            setColorNatureza('orange')

        } else {

            setColorNatureza('white')

        }
        

    }, [codigoNatureza])



    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (itemContabilDescri != '' && itemContabil == null) {

            var dataPost = {

                itemContabilDescri: itemContabilDescri,

            }

            console.log(dataPost)

            api.post('buscaItemContabil', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoItemContabil(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoItemContabil('');setItemContabil(rs.itemContabil);setItemContabilDescri(`${rs.itemContabil} - ${rs.itemContabilDescri}`)}}><font color='purple'>[{rs.itemContabil}]</font> - {rs.itemContabilDescri}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemContabilDescri])




    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (naturezaDescri != '' && codigoNatureza == null) {

            var dataPost = {

                naturezaDescri: naturezaDescri,

            }

            //console.log(dataPost)

            api.post('buscaNatureza', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoNatureza(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoNatureza('');setCodigoNatureza(rs.codigoNatureza);setNaturezaDescri(`${rs.codigoNatureza} - ${rs.naturezaDescri}`)}}><font color='purple'>[{rs.codigoNatureza}]</font> - {rs.naturezaDescri}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [naturezaDescri])



    const exportaExcel = async () => {



        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            area: area,
            fluxoCaixa: fluxoCaixa,

        }

        await api.post('demonstrativoFinanceiroDetalheXLS', dataPost).then((result) => {

            //console.log(result.data)
            window.toastr.info('Aguarde...')

            sleep(10000).then(() => {
            
                window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)

            })

            
           
            

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const atualiza = () => {

        
        //setando data1
        var data1 = moment(startDate,'YYYY-MM-DD');
        //setando data2
        var data2 = moment(endDate,'YYYY-MM-DD');
        //tirando a diferenca da data2 - data1 em dias
        var diff  = data2.diff(data1, 'days');

        if (diff < 0) {

            window.toastr.error('Data início não pode ser maior que a data fim!')
            return false

        }


        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            area: area,
            fluxoCaixa: fluxoCaixa,

        }

        setCarregando('block')

        api.post('demonstrativoFinanceiroDetalhe', dataPost).then((result) => {

            /*
            setResultado(result.data[0].map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>

                    <td>{rs.codigoNatureza}</td>
                    <td>{rs.natureza}</td>
                    <td>{rs.itemConta}</td>
                    <td>{rs.tipo}</td>
                    <td>{rs.fluxoCaixa}</td>
                    <td>{rs.area}</td>
                    <td>{rs.itemContaDescri}</td>
                    <td style={{ textAlign: 'center'}}>{rs.data}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalReceitas ? rs.totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalDespesas ? rs.totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>


                </tr>
            
            ))

            
            setResultadoReceber(result.data[3].map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>
                    <td>{rs.codigoNatureza}</td>
                    <td>{rs.natureza}</td>
                    <td>{rs.itemConta}</td>
                    <td>{rs.tipo}</td>
                    <td>{rs.fluxoCaixa}</td>
                    <td>{rs.area}</td>
                    <td>{rs.itemContaDescri}</td>
                    <td style={{ textAlign: 'center'}}>{rs.data}</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>
                    <td style={{ textAlign: 'right'}}>{rs.total ? rs.total.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>
                </tr>

            
            ))



            setResultadoPagar(result.data[2].map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>
                    <td>{rs.codigoNatureza}</td>
                    <td>{rs.natureza}</td>
                    <td>{rs.itemConta}</td>
                    <td>{rs.tipo}</td>
                    <td>{rs.fluxoCaixa}</td>
                    <td>{rs.area}</td>
                    <td>{rs.itemContaDescri}</td>
                    <td style={{ textAlign: 'center'}}>{rs.data}</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>
                    <td style={{ textAlign: 'right'}}>0,00</td>                    
                    <td style={{ textAlign: 'right'}}>0,00</td>
                    <td style={{ textAlign: 'right'}}>{rs.total ? rs.total.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>
                </tr>

            
            ))
            */
            //console.log(result.data[1])

            setTotal(result.data.total)
            setTotalPagar(result.data.totalPagar)
            setTotalReceber(result.data.totalReceber)
            setTotalReceitas(result.data.totalReceitas)
            setTotalDespesas(result.data.totalDespesas)
            setTotalPrev(result.data.totalPrev)
            setTotalResultado(result.data.totalResultado)
            setCarregando('none')

        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })

    }

    const listaFLuxoCaixa = () => {


        api.get('fluxoCaixa').then((result) => {

            //console.log(result.data)
            setResultadoFluxoCaixa(result.data.map((rs, key) => 
            
                <option value={rs.fluxoCaixa} key={key}>{rs.fluxoCaixa}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const listaArea = () => {

        api.post('listaAreaFiltro').then((result) => {

            
            setResultadoArea(result.data.map((rs, key) => {

                if (perfil == 'Administrador' || perfil == 'Gestor' || perfil == 'Financeiro' || (areasFinanceirasLogin.includes(rs.area)) )

                    return (

                        <option value={rs.area} key={key}>{rs.area}</option>
                    
                    )

                }
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    //buscaItemContabil

    useEffect(() => {

        listaFLuxoCaixa()
        listaArea()
        consultaDataUltimoMovimento()


    }, [])

    useEffect(() => {

        if (perfil != 'Administrador' && perfil != 'Gestor' && perfil != 'Financeiro') {

            if (areasFinanceirasLogin.length > 0) {

                setArea(areasFinanceirasLogin[0])

            }

        }

    }, [perfil])

    return (

        <div>

                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar XLSX </button>
                        
                <br/><br/>

                <div className="row">

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Inicial</label>
                            <DatePicker   selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Final</label>
                            <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA"
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-3 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Tipo de demonstrativo</label>
                            <select type="text" className="form-control" value={tipoDemonstrativo} onChange={event => setTipoDemonstrativo(event.target.value)}>
                                <option value="T">Total</option>
                                <option value="O">Operacionais</option>
                                <option value="E">Especiais</option>
                            </select>
                        </fieldset>
                    </div>
                    
                    <div className="col-md-4 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Fluxo de Caixa</label>
                            <select type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)} >

                                <option value="">[Selecione]</option>
                                {resultadoFluxoCaixa}


                            </select>
                                
                        </fieldset>
                    </div>
                   
                    <div className={`col-md-8 col-sm-3`}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Área</label>
                            <select type="text" className="form-control" value={area} onChange={event => setArea(event.target.value)} >

                                {
                                    perfil == 'Administrador' || perfil == 'Gestor' || perfil == 'Financeiro' ? 
                                    (
                                        <option value="" >[Selecione]</option>
                                    ) :
                                    null
                                }
                                
                                {resultadoArea}
                                

                            </select>
                                
                        </fieldset>
                    </div>


                    <div className="col-md-6 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Natureza</label>
                            <input type="text" placeholder='Busque pela Natureza...' className="form-control" style={{ backgroundColor: colorNatureza }} value={naturezaDescri} onChange={event => setNaturezaDescri(event.target.value)} onFocus={() => {setCodigoNatureza();setNaturezaDescri('')}} />
                            {resultadoNatureza}
                                
                        </fieldset>
                    </div>

                    <div className="col-md-6 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Item Contábil</label>
                            <input type="text" placeholder='Busque pelo Item Contábil...' className="form-control" style={{ backgroundColor: colorItemContabil }} value={itemContabilDescri} onChange={event => setItemContabilDescri(event.target.value)} onFocus={() => {setItemContabil();setItemContabilDescri('')}} />
                            {resultadoItemContabil}
                              
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Último movimento</label>
                            <input type="text" className="form-control" value={dataUltimoMovimento} disabled style={{ backgroundColor: '#f7e1c1'}} />
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">&nbsp;</label>
                            
                            <button type="button" class="btn btn-inline btn-info" onClick={() => atualiza()}>Consultar</button>

                        </fieldset>
                    </div>



                </div>

                <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                        
                <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>

                    <table id="table-sm"  className="table table-no-border table-hover table-sm" >

                        <tr style={{ textAlign: 'center'}}>
                            <th rowspan="2" style={{ borderTopColor: '#FFF'}}>&nbsp;</th>
                            <th colspan="2" style={{ width: '40%', backgroundColor: '#EFEFEF' }}>

                                Realizado

                            </th>
                            <th rowspan="4" style={{ width: '1%', borderTopColor: '#FFF' }}>
                                &nbsp;
                            </th>
                            <th colspan="2" style={{ width: '40%', backgroundColor: '#EFEFEF' }}>

                                Realizar

                            </th>

                        </tr>
                        <tr style={{ textAlign: 'center'}}>
                            
                            <th style={{ backgroundColor: '#afd8e0'}}>
                                Receitas
                            </th>
                            <th style={{ backgroundColor: '#e0baaf'}}>
                                Despesas
                            </th>
                            
                            <th style={{ backgroundColor: '#afd8e0'}}>
                                Contas a Receber
                            </th>
                            <th style={{ backgroundColor: '#e0baaf'}}>
                                Contas a Pagar
                            </th>
                        </tr>
                        <tr >
                                <th rowspan="2" style={{ backgroundColor: '#EFEFEF', textAlign: 'center'}}>Total</th>
                                <th style={{ textAlign: 'right'}}>
                                    {totalReceitas ? totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ textAlign: 'right', color: 'red'}}>
                                    {totalDespesas ? totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ textAlign: 'right'}}>
                                    {totalReceber ? totalReceber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ textAlign: 'right', color: 'red'}}>
                                    {totalPagar ? '-' + totalPagar.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>

                        </tr>
                        <tr style={{ textAlign: 'right'}}>
                            
                            <th colspan="2" style={{ backgroundColor: total < 0 ? '#fcedf1' : '#e9f5ec'}}>{total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</th>
                            <th colspan="2" style={{ backgroundColor: totalPrev < 0 ? '#fcedf1' : '#e9f5ec'}}>{totalPrev.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</th>
                        </tr>


                    </table>

                    <br/>

                    <table id="table-sm"  className="table table-bordered table-hover table-sm" >

                        <tr style={{ textAlign: 'center'}}>
                            <th style={{ width: '33%', backgroundColor: '#EFEFEF' }}>
                                Resultado
                            </th>
                            <th style={{ width: '33%', backgroundColor: totalResultado < 0 ? '#fcedf1' : '#e9f5ec' }}>
                                {totalResultado ? totalResultado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                            </th>
                            <th style={{ width: '33%', backgroundColor: totalResultado < 0 ? '#fcedf1' : '#e9f5ec' }}>
                                { totalResultado ? totalResultado > 0 ? 'Superavit' : totalResultado < 0 ? 'Deficit' : null : null}
                            </th>
                        </tr>
                       

                    </table>



                    { /*
                    <br/>

                    <table id="table-sm" class="table table-bordered table-hover table-sm" style={{ display: 'none' }}>
                        
                        <thead>
                            <tr style={{ textAlign: 'right', fontSize: '11px'}}>
                                <th colspan="8"  >Total</th>
                                <th colspan="2" style={{ backgroundColor: total < 0 ? '#f7e1e6' : '#d1f0d9'}}>{total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</th>
                                <th colspan="2" style={{ backgroundColor: totalPrev < 0 ? '#f7e1e6' : '#d1f0d9'}}>{totalPrev.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</th>
                            </tr>
                            <tr style={{ fontSize: '11px'}}>
                                <th>Cód. Natureza</th>
                                <th>Natureza</th>
                                <th>Item Contábil</th>
                                <th>Tipo</th>
                                <th>Fluxo de Caixa</th>
                                <th>Área</th>
                                <th>Item Contábil</th>
                                <th style={{ textAlign: 'center'}}>Data</th>
                                <th style={{ backgroundColor: '#afd8e0'}} ><center>Receitas</center></th>
                                <th style={{ backgroundColor: '#e0baaf'}} ><center>Despesas</center></th>
                                <th style={{ backgroundColor: '#afd8e0'}} ><center>Receber</center></th>
                                <th style={{ backgroundColor: '#e0baaf'}} ><center>Pagar</center></th>
                            </tr>
                            <tr>
                                <th colspan="8" style={{ fontSize: '11px', textAlign: 'right'}}>

                                    Total

                                </th>
                                <th style={{ fontSize: '11px', textAlign: 'right', backgroundColor: '#e1ebed'}}>
                                    {totalReceitas ? totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ fontSize: '11px', textAlign: 'right', backgroundColor: '#ede4e1'}}>
                                    {totalDespesas ? totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ fontSize: '11px', textAlign: 'right', backgroundColor: '#e1ebed'}}>
                                    {totalReceber ? totalReceber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                                <th style={{ fontSize: '11px', textAlign: 'right', backgroundColor: '#ede4e1'}}>
                                    {totalPagar ? totalPagar.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultado}
                            {resultadoReceber}
                            {resultadoPagar}
                            
                        </tbody>
                        <tfoot>
                            <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                <th colspan="8" style={{ textAlign: 'right'}}>
                                    Total
                                </th>
                                <th >

                                    {totalReceitas ? totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                                </th>
                                <th  >

                                    {totalDespesas ? totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                                </th>
                                <th  >

                                    {totalReceber ? totalReceber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                                </th>
                                <th  >

                                    {totalPagar ? totalPagar.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                                </th>
                            </tr>
                        </tfoot>
                        
                    </table>

                    */}

                </div>

        </div>

    )

}


export default DemonstrativoFinanceiroDetalhado