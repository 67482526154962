import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalTeto from '../modal/ModalTeto'

const Tetos = () => {

    const navigate = useNavigate()

    const [resultado, setResultado] = useState([])
    const [teto_id, setTeto_id] = useState()


    const consulta = () => {


        api.get('tetos').then((result) => {

            console.log(result.data)      
            setResultado(result.data)      


        }).catch((err) => {

            console.log(err.response)

        })


    }

      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'ano', //access nested data with dot notation
            header: 'Ano',
            
          },
         
          {
            accessorKey: 'valor', //normal accessorKey
            header: 'Valor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
         
          
        ],
        [],
      );



    useEffect(() => {

        consulta()


    }, [])


    return (

        <div>

            <h5 className="m-t-lg with-border">Administração - Tetos</h5>


                        

            <button type="button" class="btn btn-inline btn-success" onClick={() => {window.$('#modalTeto').modal('show');setTeto_id()}}>+ Teto</button>
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                    fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                    },
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/Teto/${row.original.teto_id}`)
                    window.$('#modalTeto').modal('show');
                    setTeto_id(row.original.teto_id)

                    },
                    sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
                
            />


            <ModalTeto teto_id={teto_id} consulta={consulta} />

        </div>

    )

}

export default Tetos